// stylelint-disable selector-no-qualifying-type, no-descending-specificity
.b-checkbox {
	background-color: transparent;
	border: none;
	font-family: $font-sans;
	font-size: 14px;
	position: relative;
	user-select: none;

	&-assistive_text {
		@include hide(visually);
	}

	&-label {
		color: $color-text;
		cursor: pointer;
		display: inline-block;
		font-family: $font-sans;
		line-height: 22px;
		padding: rh(0 0 0 8);
		position: relative;
		text-align: left;
		text-transform: none;

		&::before {
			@include g-checkbox;

			content: '';
			display: inline-block;
			height: 22px;
			left: 0;
			position: absolute;
			top: 0;
			width: 22px;
			z-index: z(components, checkbox, before);
		}
	}

	&-label.m-selected {
		&::before {
			@include g-checkbox(_checked);
		}
	}

	&:hover {
		.b-checkbox-label {
			&::before {
				@include g-checkbox(_hover);
			}
		}
	}

	&:disabled,
	&.m-disabled {
		cursor: default;
		pointer-events: none;

		.b-checkbox-label {
			color: $color-disabled;

			.b-checkbox-color_name {
				color: $color-disabled;
			}

			&::before {
				@include g-checkbox(_disabled);
			}
		}
	}
}

.b-card_refinement-list_item.js-selected .b-checkbox-label::before {
	@include g-checkbox(_checked);
}

// stylelint-enable
