.b-plp_header {
	border-bottom: 1px solid $color-divider;
	min-height: 115px;
	padding: rh(0 0 5);
	position: relative;

	@include media(sm) {
		border-bottom: 0;
	}

	.b-theme_hmv & {
		border-bottom: 0;
	}

	&-filters-selected-mobile {
		width: 100%;
		border-bottom: 1px solid var(--color-brand88);

		@include media(lg-up) {
			display: none;
		}

		&--container {
			width: 300px;
			text-align: left;

			& .b-applied_filters { // stylelint-disable-line
				margin-left: 0;
				margin-top: 0;

				& .b-applied_filters-aside { // stylelint-disable-line
					text-align: left !important;// stylelint-disable-line
				}
			}

			& .js-filter-bar {// stylelint-disable-line
				display: flex;
				margin-bottom: 0;
			}
		}
	}

	&-title {
		font: 30px/1.2 $font-serif;
		margin: -0.15em rh(0 5);

		@include media(sm) {
			margin: rh(0 0 3);
		}

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}

		.b-theme_hmv & {
			font-weight: 900;
		}
	}

	&-line {
		align-items: center;
		color: $color-grey44;
		display: flex;
		justify-content: space-between;

		@include media(sm) {
			flex-direction: column;
		}
	}

	&-filters {
		@include media(sm) {
			display: flex;
			width: 100%;
		}

		@include media(md) {
			display: flex;
		}

		&.filter-sticky {
			@include media(sm) {
				background-color: $color-white;
				border-bottom: 1px solid $color-brand88;
				margin-top: -3px;
				max-width: 767px;
				padding: 0 20px;
				position: fixed;
				width: 100%;
				z-index: 2;
			}
		}
	}

	&-sort {
		margin-left: auto;
		max-width: 256px;
		min-width: 190px;

		@include media(md-down) {
			max-width: 100%;
		}

		@include media(sm) {
			margin: rh(5 0);
			min-width: 50%;
			width: 50%;

			.filter-sticky & select {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}

	&-results_count {
		margin-right: auto;
		padding-right: rh(2);

		@include media(sm) {
			border-bottom: 1px solid $color-divider;
			padding-bottom: rh(7);
			padding-right: 0;
			width: 100%;
		}
	}

	&-aside_toggle {
		display: none;
		margin-left: rh(4);

		@include media(md-down) {
			align-self: center;
			display: inline-block;
			width: 50%;
		}

		@include media(sm) {
			margin-left: rh(0);

			.filter-sticky & {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
				margin: rh(5 0);
			}
		}
	}

	&-search {
		// Search phrase on search result template

		line-height: 27px;
		padding-right: rh(2);

		@include media(sm) {
			padding-right: 0;
		}
	}

	.filter-sticky {
		@include media(sm) {
			display: flex;
		}
	}
}

.js-header-bar {
	.js-results-popup {
		display: none;
	}
}
