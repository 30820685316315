@mixin g-checkbox(
	$_: root
) {
	$border: 0 0 0 1px $color-brand;
	$check-icon-toys: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{url-color-legacy($color-blue)}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M12.647%201.857L6.302%207.674c-.47.43-1.232.43-1.702%200L1.325%204.67a1.04%201.04%200%200%201%200-1.56%201.28%201.28%200%200%201%201.702%200l2.424%202.223L10.944.297a1.28%201.28%200%200%201%201.703%200%201.04%201.04%200%200%201%200%201.56z%27%2F%3E%3C%2Fsvg%3E';
	$check-icon-babies: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{url-color-legacy($color-purple)}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M12.647%201.857L6.302%207.674c-.47.43-1.232.43-1.702%200L1.325%204.67a1.04%201.04%200%200%201%200-1.56%201.28%201.28%200%200%201%201.702%200l2.424%202.223L10.944.297a1.28%201.28%200%200%201%201.703%200%201.04%201.04%200%200%201%200%201.56z%27%2F%3E%3C%2Fsvg%3E';
	$check-icon-hmv: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{url-color($color-hmv-pink)}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M12.647%201.857L6.302%207.674c-.47.43-1.232.43-1.702%200L1.325%204.67a1.04%201.04%200%200%201%200-1.56%201.28%201.28%200%200%201%201.702%200l2.424%202.223L10.944.297a1.28%201.28%200%200%201%201.703%200%201.04%201.04%200%200%201%200%201.56z%27%2F%3E%3C%2Fsvg%3E';
	$check-icon-rle: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{url-color-legacy($color-grey)}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M12.647%201.857L6.302%207.674c-.47.43-1.232.43-1.702%200L1.325%204.67a1.04%201.04%200%200%201%200-1.56%201.28%201.28%200%200%201%201.702%200l2.424%202.223L10.944.297a1.28%201.28%200%200%201%201.703%200%201.04%201.04%200%200%201%200%201.56z%27%2F%3E%3C%2Fsvg%3E';
	$check-icon-rns: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{url-color-legacy($color-teal-dark)}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M12.647%201.857L6.302%207.674c-.47.43-1.232.43-1.702%200L1.325%204.67a1.04%201.04%200%200%201%200-1.56%201.28%201.28%200%200%201%201.702%200l2.424%202.223L10.944.297a1.28%201.28%200%200%201%201.703%200%201.04%201.04%200%200%201%200%201.56z%27%2F%3E%3C%2Fsvg%3E';

	@if $_ == root {
		appearance: none;
		background: url($check-icon-toys) center 11px no-repeat;
		background-size: 0;
		border: none; // Border not rendered in IE11 so we move all to box shadow
		border-radius: $global-radius;
		box-shadow: $border;
		cursor: pointer;
		display: inline-block;
		height: 22px;
		margin-right: rh(2);
		transition: all 0.1s ease;
		width: 22px;

		.b-theme_babies & {
			background-image: url($check-icon-babies);
		}

		.b-theme_rle & {
			background-image: url($check-icon-rle);
		}

		.b-theme_hmv & {
			background-image: url($check-icon-hmv);
			--color-brand: var(--color-neutral-400);
		}

		.b-theme_roomsandspaces & {
			background-image: url($check-icon-rns);
		}

		&::-ms-check {
			display: none;
		}
	}

	@if $_ == _hover {
		box-shadow: $border, $depth-1;
	}

	@if $_ == _checked {
		background-position: center 6px;
		background-size: 12px;
	}

	@if $_ == _invalid {
		box-shadow: 0 0 0 1px $color-red;
	}

	@if $_ == _invalid-hover {
		box-shadow: 0 0 0 1px $color-red, 0 3px 5px rgba($color-red, 0.3);
	}

	@if $_ == _disabled {
		box-shadow: $border;
		cursor: default;
		opacity: 0.6;
	}
}
