/* stylelint-disable */
.b-search_noresults {
	margin-bottom: rh(12);
	text-align: center;

	&-title {
		font: 30px/1.2 $font-serif;
		margin-bottom: rh(4);

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}
	}

	&-message {
		font-size: 17px;

		span {
			font-weight: 600;

			.b-theme_rle & {
				font-weight: 500;
			}
		}
	}
}
