.b-rating_checkbox {
	&-quantity {
		color: darken($color-white, 35);
		vertical-align: middle;
	}

	.b-checkbox-label {
		line-height: 15px;
	}

	&-star {
		transform: scale(1.2);
		vertical-align: middle;

		path {
			fill: darken($color-white, 9);
		}
	}

	&-star_full {
		vertical-align: middle;

		path {
			fill: $color-brand;
		}
	}
}
