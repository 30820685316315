.b-no_results_content {
	margin: rh(8 0 50 0);
	max-width: 380px;

	@include media(md-down) {
		margin: rh(4) auto rh(30) auto;
		text-align: center;
	}

	&-btn {
		margin-top: rh(12);
		max-width: 204px;
		padding: rh(0 2);

		@include media(sm) {
			display: inline-block;
			margin-top: rh(6);
		}
	}

	&-btn_desktop {
		display: none;

		@include media(lg-up) {
			display: inline-block;
		}
	}

	&-btn_mobile {
		display: none;

		@include media(md-down) {
			display: inline-block;
		}
	}
}
