.b-refinement_radio {
	@include g-radio;

	&-icon {
		@include g-radio(_input);

		.b-card_refinement-list_item.js-selected & {
			@include g-radio(_input-checked);
		}

		.b-refinement_radio:hover & {
			@include g-radio(_input-hover);
		}

		.b-card_refinement-list_item.js-selected .b-refinement_radio:hover & {
			@include g-radio(_input-checked-hover);
		}

		&.m-disabled {
			@include g-radio(_input-disabled);
		}
	}

	&-label {
		@include g-radio(_label);

		font-size: 14px;
		text-align: left;
	}

	&-quantity {
		color: $color-grey65;
	}
}
