@mixin g-radio(
	$_: root,
	$_state: _stale
) {
	$border: 0 0 0 1px $color-brand;
	$checked: 0 0 0 5px $color-white inset;
	$stale: 0 0 0 9px $color-white inset;

	@if $_ == root {
		appearance: none;
		background: transparent;
		border: none;
		cursor: pointer;
		padding: 0 0 0 1px;
		position: relative;
		user-select: none;
	}

	@if $_ == _label {
		color: $color-text;
		cursor: pointer;
		display: inline-block;
		font: 16px/1.5 $font-sans;
	}

	@if $_ == _input {
		appearance: none;
		background: $color-brand;
		border: none; // Border not rendered in IE11 so we move all to box shadow
		border-radius: 18px;
		box-shadow: $border, $stale;
		display: inline-block;
		height: 18px;
		margin-right: rh(2);
		min-height: 18px;
		min-width: 18px;
		transition: box-shadow $motion-ease;
		vertical-align: sub;
		width: 18px;

		&::-ms-check {
			display: none;
		}
	}

	@if $_ == _input-checked {
		box-shadow: $border, $checked;
	}

	@if $_ == _input-hover {
		box-shadow: $border, $stale, $depth-1;
	}

	@if $_ == _input-checked-hover {
		box-shadow: $border, $checked, $depth-1;
	}

	@if $_ == _input-disabled {
		background-color: $color-grey65;
		box-shadow: none;
		cursor: default;
		opacity: 0.6;

		&::after {
			color: $color-white;
			content: '\00d7'; // This will render as a 'X'
			display: inline-block;
			font-size: 26px;
			height: 18px;
			left: 3px;
			min-height: 18px;
			min-width: 18px;
			position: absolute;
			top: -2px;
		}
	}
}
