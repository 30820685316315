.l-plp-main_container {
	display: flex;

	.l-plp-refinements_container {
		@include media(lg-up) {
			width: 25%;
		}
	}

	.l-plp_grid {
		display: flex;
		flex-wrap: wrap;
		height: auto;
		justify-content: flex-start;

		@include media(lg-up) {
			margin: 28px 0 28px 28px;
			width: 75%;
			display: block;
		}

		.b-product_tile {
			margin-bottom: rh(9);
			margin-left: 27px; // for IE11 rh(7) - 1px
			max-height: fit-content;
			width: calc((100% - 85px) / 3);

			@include media(sm) {
				margin-left: 0;
				margin-right: 0;
				width: 50%;
			}

			&-container {
				display: flex;
				height: fit-content;
				flex-wrap: wrap;
			}
		}

		&-footer {
			min-width: 100%;
			width: 100%;

			.search-keywords {
				display: none;
			}
		}
	}
}

.b-pd-item {
	@include media(md-down) {
		margin-top: 15px;
	}
}
