.b-load_more {
	color: $color-grey44;
	font-size: 14px;
	margin: rh(0);
	padding: rh(7 0 0);
	position: relative;
	text-align: center;
	transition: border-color ease 0.6s;

	@include media(md-down) {
		border-top: 1px solid $color-divider;
	}

	@include media(md-up) {
		font-size: 16px;
	}

	&-btn {
		display: block;
		margin: rh(7) auto;
		max-width: 150px;
		padding: rh(0 1);
	}

	.b-plp_header-results_count {
		border: none;
		padding-bottom: rh(0);
	}

	&.m-loading {
		border-color: transparent;
	}

	&-caption {
		margin: rh(0 0 7);
		transition: opacity ease 0.6s;

		.b-load_more.m-loading & {
			opacity: 0;
		}
	}

	&-spinner {
		left: 50%;
		margin: -19px 0 0 -19px;
		opacity: 0;
		position: absolute;
		stroke: $color-brand;
		top: 50%;
		transition: ease 0.6s;
		transition-property: opacity, visibility;
		visibility: hidden;

		.b-load_more.m-loading & {
			opacity: 1;
			visibility: visible;
		}
	}

	&-spinner_line {
		transform-origin: 18px 18px;

		.b-load_more.m-loading & {
			animation: rotate infinite ease 1s;
		}
	}

	&-button {
		margin: rh(7) auto;
		transition: opacity ease 0.6s, background-color ease 0.4s;

		@include media(md-up) {
			width: auto;
		}

		.b-load_more.m-loading & {
			opacity: 0;
		}
	}

	&.m-order_history {
		border: none;
		padding: 0;

		.b-load_more-button {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
}
