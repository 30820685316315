.b-refinements {
	@include media(lg-up) {
		width: 300px;
		padding-bottom: 38px;
	}

	&-mobile {
		@include media(lg-up) {
			display: none;
		}
	}

	&-desktop {
		@include media(md-down) {
			display: none;
		}
	}

	&-panel_top {
		align-items: center;
		background-color: $color-white;
		color: $color-brand;
		display: flex;
		font: bold 18px/1.2 $font-sans;
		height: 60px;
		justify-content: space-between;
		padding: rh(0 7);

		@include media(sm) {
			padding: rh(0 5);
		}

		@include media(lg-up) {
			display: none;
		}
	}

	&-panel_actions {
		display: flex;
		justify-content: center;
		padding: rh(0 6);

		@include media(md-down) {
			padding: rh(0);
		}

		&.js-clear {
			padding: 0;
		}
	}

	&-panel_btn,
	&-btn {
		padding: rh(0 1);
	}

	&-panel_btn {
		&:first-child {
			margin-right: rh(3);
		}
	}

	&-close_icon {
		background: transparent;
		border: none;
		color: $color-brand;
		cursor: pointer;
		padding: 0;

		svg {
			display: block;
		}
	}
}
