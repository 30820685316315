.b-product_related-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 20px;
	margin-bottom: rh(5);

	&-title {
		color: $color-text;
		font-family: $font-serif;
		font-size: 24px;
		font-weight: normal;
		letter-spacing: 0.03em;
		line-height: 34px;
		margin-bottom: rh(4);
	}

	&-link {
		display: block;
		text-align: left;

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}
}
