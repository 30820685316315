/*md

# Color checkbox example

<button class="b-color_swatch ${!refinementValue.selectable ? 'm-disabled disabled' : ''} ${(refinementValue.selected && refinementValue.selectable) ? 'm-selected' : ''}"
            data-href="${refinementValue.url}">
        <span class="b-color_swatch-circle">
            <span class="b-color_swatch-color" style="background: ${refinementValue.displayValue} url(${refinementValue.images['swatch'][0].url})"></span>
            <isif condition="${refinementValue.selected && refinementValue.selectable}">
                <svg class="b-color_swatch-selected_icon" xmlns="http://www.w3.org/2000/svg" width="12" height="8">
                    <path fill="currentColor" fill-rule="evenodd" d="M11.617 1.858L5.306 7.675a1.27 1.27 0 0 1-1.693 0L.354 4.672a1.045 1.045 0 0 1 0-1.561 1.271 1.271 0 0 1 1.694 0L4.46 5.334 9.924.297a1.27 1.27 0 0 1 1.693 0c.468.431.468 1.13 0 1.561z"/>
                </svg>
            </isif>
        </span>
        <span class="b-color_swatch-label ${refinementValue.swatchId}">
            <span class="b-color_swatch-text">${refinementValue.displayValue}</span>
            <span class="b-color_swatch-quantity">${Resource.msgf('out.brackets.round', 'common', null, refinementValue.hitCount)}</span>
        </span>
        <span id="${refinementValue.swatchId}-assistive" class="b-color_swatch-assistive_text sr-only selected-assistive-text">
            ${refinementValue.selected && refinementValue.selectable ? Resource.msg('msg.assistive.selected.text', 'common', null) : ''}
            ${refinementValue.title}
            ${Resource.msgf('out.brackets.round', 'common', null, refinementValue.hitCount)}
        </span>
    </button>
*/

.b-color_swatch {
	align-items: center;
	background: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	padding: 0;
	position: relative;
	user-select: none;

	&-label {
		color: $color-text;
		font-family: $font-sans;
		font-size: 14px;
		margin-left: rh(3);
	}

	&-quantity {
		color: darken($color-white, 35);
	}

	&-quantity,
	&-text {
		vertical-align: middle;
	}

	&-circle {
		align-items: center;
		border-radius: 50%;
		display: flex;
		height: 40px;
		justify-content: center;
		transition: box-shadow 0.3s ease;
		width: 40px;
	}

	&-color {
		background-color: $color-brand97;
		background-size: cover;
		border: 1px solid var(--color-brand);
		border-radius: 50%;
		display: inline-block;
		height: 30px;
		left: 5px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 30px;

		&-tan {
			background-color: #b9a28a;
		}

		&-grey-mix {
			background-color: #a7a9a5;
		}

		&-dark-rose {
			background-color: #ad6d7f;
		}

		&-sand {
			background-color: #b4825b;
		}

		&-charmix {
			background-color: #6c6868;
		}

		&-caramel {
			background-color: #884d23;
		}

		&-mid-red {
			background-color: #a11729;
		}

		&-white {
			background-color: #f5f5f5;
		}

		&-blue {
			background-color: #244373;
		}

		&-bone {
			background-color: #9d7446;
		}

		&-pink {
			background-color: #d18489;
		}

		&-mid-pink {
			background-color: #dcb1af;
		}

		&-ivory {
			background-color: #efeada;
		}

		&-ecru {
			background-color: #dccdbb;
		}

		&-brown {
			background-color: #70483a;
		}

		&-light-grey-mix {
			background-color: #f3ebdf;
		}

		&-cream {
			background-color: #f3ebdf;
		}

		&-green {
			background-color: #10564b;
		}

		&-black {
			background-color: #28282c;
		}

		&-light-fig {
			background-color: #7c4c53;
		}

		&-navy {
			background-color: #262b37;
		}

		&-dark-pink {
			background-color: #a75d67;
		}

		&-rust {
			background-color: #a2574b;
		}

		&-sky {
			background-color: #677283;
		}

		&-leaf {
			background-color: #638489;
		}

		&-oatmix {
			background-color: #dccdbb;
		}

		&-light-pink {
			background-color: #f8c9c9;
		}

		&-red {
			background-color: #8e2131;
		}

		&-dark-coral {
			background-color: #c08a80;
		}

		&-burgundy {
			background-color: #5c2935;
		}

		&-dark-brown {
			background-color: #805c53;
		}

		&-mocha {
			background-color: #a37863;
		}

		&-dark-blue {
			background-color: #35435a;
		}

		&-grape {
			background-color: #886971;
		}

		&-dark-mustard {
			background-color: #835b37;
		}

		&-light-blue {
			background-color: #8699ab;
		}

		&-light-mauve {
			background-color: #c89fa5;
		}

		&-fig {
			background-color: #532d3b;
		}

		&-charcoal {
			background-color: #4e4a4f;
		}

		&-light-tan {
			background-color: #b7a792;
		}

		&-mid-grey-mix {
			background-color: #babcb0;
		}

		&-mid-green {
			background-color: #37413a;
		}

		&-dark-clay {
			background-color: #92423b;
		}

		&-dark-red {
			background-color: #7b1f2e;
		}

		&-light-purple {
			background-color: #b598a3;
		}

		&-dark-caramel {
			background-color: #884d23;
		}

		&-mid-navy {
			background-color: #2b2d42;
		}

		&-dark-green {
			background-color: #213631;
		}

		&-honey {
			background-color: #b68f52;
		}

		&-yellow {
			background-color: #efda4f;
		}

		&-mid-mauve {
			background-color: #ad6d7f;
		}

		&-dark-fig {
			background-color: #533146;
		}

		&-olive {
			background-color: #655f49;
		}

		&-light-rust {
			background-color: #ad5d5d;
		}

		&-coral {
			background-color: #e2a9a1;
		}

		&-purple {
			background-color: #800080;
		}

		&-orange {
			background-color: #ffa500;
		}

		&-grey {
			background-color: #808080;
		}

		&-light-green {
			background-color: #90ee90;
		}

		&-mid-olive {
			background-color: #808000;
		}

		&-graphite {
			background-color: #251607;
		}
	}

	&-selected_icon {
		left: 14px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		visibility: hidden;
		z-index: z(components, checkbox, icon);

		&-tan {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-grey-mix {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-rose {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-sand {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-charmix {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-caramel {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-mid-red {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-white {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-blue {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-bone {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-pink {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-mid-pink {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-ivory {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-ecru {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-brown {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-grey-mix {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-cream {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-green {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-black {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-fig {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-navy {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-pink {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-rust {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-sky {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-leaf {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-oatmix {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-pink {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-red {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-coral {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-burgundy {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-brown {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-mocha {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-blue {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-grape {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-mustard {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-blue {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-mauve {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-fig {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-charcoal {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-tan {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-mid-grey-mix {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-mid-green {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-clay {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-red {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-purple {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-caramel {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-mid-navy {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-green {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-honey {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-yellow {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-mid-mauve {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-dark-fig {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-olive {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-rust {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-coral {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-purple {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-orange {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-grey {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-light-green {
			color: $color-black;

			path {
				stroke: rgba($color-black, 0.2);
				stroke-width: 1px;
			}
		}

		&-mid-olive {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}

		&-graphite {
			color: $color-white;

			path {
				stroke: rgba($color-white, 0.2);
				stroke-width: 1px;
			}
		}
	}

	&-assistive_text {
		@include hide(visually);
	}

	&.m-selected &-selected_icon {
		visibility: visible;
	}

	&.m-unselectable {
		cursor: default;
		opacity: 0.5;
	}

	&-img {
		border: 1px solid var(--color-brand);
		border-radius: 50%;
		height: 30px;
		max-width: 30px;
		width: 30px;
	}
}
