.js-filter-bar {
	display: none;
}

.b-applied_filters {
	margin: 15px;
	width: 100%;

	@include media(md-down) {
		flex-wrap: wrap;
		margin-bottom: 0;
		margin-top: 20px;
	}

	@include media(sm) {
		margin-top: rh(2);
	}

	&.js-filter-bar {
		margin-bottom: 0;

		&.js-clear-cta {
			margin-bottom: 15px;
		}
	}

	&-inner {
		display: flex;

		@include media(md-down) {
			flex-direction: column;
		}
	}

	&-main {
		margin-right: auto;

		@include media(md) {
			order: 2;
		}
	}

	&-assistive_text {
		@include hide(visually);
	}

	&-item {
		background: $color-brand76;
		border: 1px solid $color-brand94;
		border-radius: $global-radius;
		color: $color-brand;
		cursor: default;
		display: inline-flex;
		font-family: $font-sans;
		font-size: 14px;
		height: 30px;
		justify-content: space-between;
		line-height: 28px;
		margin: rh(0 2 2 0);
		overflow: hidden;
		padding: rh(0 0 0 2);
		transition: 0.3s ease;
		transition-property: border-color, box-shadow;
		user-select: none;
		width: 95%;

		.b-theme_hmv & {
			background-color: var(--color-brand88);
		}

		@include media(md-down) {
			width: 90%;
		}

		&:hover {
			border-color: $color-brand76;
			box-shadow: $depth-1;
		}

		.b-theme_rle & {
			font-weight: 500;
		}

		&.m-color {
			position: relative;
		}
	}

	&-remove_button {
		background-color: transparent;
		border: none;
		color: $color-brand;
		cursor: pointer;
		height: 28px;
		width: 28px;

		&:hover {
			color: $color-brand52;
		}

		svg {
			display: block;
			margin: -1px auto 0;
		}
	}

	&-reset_all {
		appearance: none;
		background: $color-white;
		border: none;
		color: $color-brand;
		cursor: pointer;
		font: bold 16px/1.2 $font-sans;
		height: 30px;
		line-height: 30px;
		padding: 0;
		text-decoration: none;
		text-transform: uppercase;
	}

	&-color_icon {
		border-radius: 50%;
		display: inline-block;
		height: 18px;
		margin-right: rh(1);
		width: 18px;

		&.m-white {
			border: 1px solid $color-brand94;
		}
	}

	&-color_txt {
		padding-right: rh(1);
	}

	&-aside {
		text-align: center;
		width: 100%;

		@include media(md-down) {
			margin-bottom: rh(5);
			order: 1;
			text-align: center;
			width: 100%;
		}

		@include media(sm) {
			margin-top: rh(5);
		}
	}

	&-noresult {
		@include media(md-down) {
			text-align: center;
		}
	}

	&-noresult_txt {
		line-height: 22px;
		margin-bottom: rh(10);
		max-width: 500px;

		@include media(md-down) {
			margin: 0 auto rh(10);
			text-align: center;
		}
	}

	&-star {
		vertical-align: sub;

		path {
			fill: darken($color-white, 9);
		}
	}

	&-star_full {
		vertical-align: sub;

		path {
			fill: $color-orange;
		}
	}
}
